<template>
  <v-container class="pa-0" style="max-width: 1200px">
    <v-card tile class="rounded-lg">
      <v-row no-gutters>
        <v-col cols="9">
          <v-card class="pa-2" flat tile>
            <v-card-subtitle class="pa-2">
              <v-row dense>
                <v-col cols="2" align-self="center">
                  <v-subheader class="primary--text"> My Tote Bag</v-subheader>
                </v-col>
                <v-col cols="10" align-self="center">
                  <v-row dense>
                    <v-btn color="success" class="mx-2">My Document</v-btn>
                    <v-btn color="warning" class="mx-2">My Itinerary</v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-card-text>
              <v-list>
                <div v-for="(item, index) in totebag_list" :key="index">
                  <v-list-item>
                    <v-checkbox
                      v-model="selected"
                      value="John"
                    ></v-checkbox>
                    <v-btn color="red" icon class="mx-1"> <v-icon>mdi-file-pdf-box-outline</v-icon> </v-btn>
                    <v-btn color="black" icon class="mx-1"> <v-icon>mdi-cloud-download-outline</v-icon> </v-btn>
                    <v-btn color="primary" class="mx-1"> View </v-btn>
                    <v-list-item-content class="mx-4">
                      <v-list-item-title
                        v-html="item.title"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider v-if="index!=totebag_list.length-1"></v-divider>
                </div>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card class="pa-2" outlined tile>
            <v-img
              src="https://via.placeholder.com/300x250?text=Advertisement Logo"
            ></v-img>
          </v-card>
          <v-card class="pa-2" outlined tile>
            <v-img
              src="https://via.placeholder.com/300x250?text=Advertisement Logo"
            ></v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
//import { HTTP } from "../../helper/axios";

export default {
  name: "SessionList",
  data() {
    return {
      selected:[],
      items: ["All", "Item 1", "Item 2", "Item 3", "Item 4"],
      totebag_list: [
        {
          path: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          title: "Item 1",
          subtitle: "Health Sparq - Account Manager",
        },
        {
          path: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          title: "Item 1",
          subtitle: "Health Sparq - Account Manager",
        },
        {
          path: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          title: "Item 1",
          subtitle: "Health Sparq - Account Manager",
        },
        {
          path: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          title: "Item 1",
          subtitle: "Health Sparq - Account Manager",
        },
        {
          path: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          title: "Item 1",
          subtitle: "Health Sparq - Account Manager",
        },
        {
          path: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          title: "Item 1",
          subtitle: "Health Sparq - Account Manager",
        },
        {
          path: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          title: "Item 1",
          subtitle: "Health Sparq - Account Manager",
        },
         {
          path: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          title: "Item 1",
          subtitle: "Health Sparq - Account Manager",
        }
      ],
      session_data: [
        {
          id: 1,
          time: "7:00am - 8:00am",
          is_upcoming: false,
          sessions: [
            {
              id: 1,
              name: "Trac session 1",
            },
            {
              id: 2,
              name: "Trac session 2",
            },
            {
              id: 3,
              name: "Trac session 3",
            },
          ],
        },
        {
          id: 2,
          time: "8:00am - 9:00am",
          is_upcoming: true,
          sessions: [
            {
              id: 4,
              name: "Trac session 4",
            },
            {
              id: 5,
              name: "Trac session 5",
            },
          ],
        },
      ],
    };
  },
};
</script>